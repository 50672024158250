import { useState, useEffect, useCallback } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';

// project import
import IncomeChart from 'sections/dashboard/analytics/IncomeChart';
import MarketingCardChart from 'sections/dashboard/analytics/MarketingCardChart';
import OrdersCardChart from 'sections/dashboard/analytics/OrdersCardChart';
import OrdersList from 'sections/dashboard/analytics/OrdersList';
import PageViews from 'sections/dashboard/analytics/PageViews';
import ReportChart from 'sections/dashboard/analytics/ReportChart';
import SalesCardChart from 'sections/dashboard/analytics/SalesCardChart';
import SalesChart from 'sections/dashboard/SalesChart';
import TransactionHistory from 'sections/dashboard/analytics/TransactionHistory';
import UsersCardChart from 'sections/dashboard/analytics/UsersCardChart';
import LabelledTasks from 'sections/dashboard/analytics/LabelledTasks';
import ReaderCard from 'sections/dashboard/analytics/ReaderCard';
import AcquisitionChannels from 'sections/dashboard/analytics/AcquisitionChannels';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import AnalyticsDataCard from 'components/cards/statistics/AnalyticsDataCard';
import { getDashboard } from '../../api/user';

// assets
import { DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';

// sales report status
const status = [
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'month',
    label: 'This Month'
  },
  {
    value: 'year',
    label: 'This Year'
  }
];

// ==============================|| DASHBOARD - ANALYTICS ||============================== //

const DashboardAnalytics = () => {
  const theme = useTheme();
  const [value, setValue] = useState('today');
  const [slot, setSlot] = useState('week');
  const [quantity, setQuantity] = useState('By Payments');
  const [transactions, setTransactions] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [sellTransactions, setSellTransactions] = useState([]);
  const [totalSellAmount, setTotalSellAmount] = useState(0);
  const [doubleSpend, setDoubleSpend] = useState([]);
  const [orderPercent, setOrderPercent] = useState(0);
  const [orderColor, setOrderColor] = useState();

  const [sellPercent, setSellPercent] = useState(0);
  const [sellColor, setSellColor] = useState();

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment) setSlot(newAlignment);
  };

  const init = useCallback(async () => {
    const res = await getDashboard();
    setTransactions(res.groupedTransactions);
    setTotalOrder(res.totalTransactions);
    setSellTransactions(res.groupSellTransactions);
    if (res.totalSellAmount != undefined) setTotalSellAmount(`$${res.totalSellAmount}`);
    let orderLength, sellLength;
    try {
      orderLength = res.groupedTransactions.length;
      sellLength = res.groupSellTransactions.length;
    }
    catch (e) {
      orderLength = 0;
      sellLength = 0;
    }
    let orderPer, sellPer;
    if (orderLength < 2) orderPer = 0;
    else {
      let orderPrev = res.groupedTransactions[orderLength - 2];
      let orderNext = res.groupedTransactions[orderLength - 1];
      if (!orderPrev && !orderNext) orderPer = 0;
      else if (!orderPrev) orderPer = 100;
      else orderPer = Number(orderNext / (orderPrev / 100) - 100).toFixed(2);
    }
    if (sellLength < 2) sellPer = 0;
    else {
      let sellPrev = res.groupSellTransactions[sellLength - 2];
      let sellNext = res.groupSellTransactions[sellLength - 1];
      if (!sellPrev && !sellNext) sellPer = 0;
      else if (!sellPrev) sellPer = 100;
      else sellPer = Number(sellNext / (sellPrev / 100) - 100).toFixed(2);
    }

    setOrderPercent(orderPer), setSellPercent(sellPer)
    if (orderPer < 0) setOrderColor("warning");
    else setOrderColor("primary");
    if (sellPer < 0) setSellColor("warning");
    else setSellColor("primary");

    setDoubleSpend(res.double)
  }, [])

  useEffect(() => {
    init()
  }, [init])

  return (
    <>
      <Grid container rowSpacing={4.5} columnSpacing={3}>
        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
          <AnalyticsDataCard title="Total Users" count="78,250">
            <UsersCardChart />
          </AnalyticsDataCard>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <AnalyticsDataCard title="Total Order" count={totalOrder} percentage={orderPercent} color={orderColor}>
            <OrdersCardChart order={transactions} />
          </AnalyticsDataCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <AnalyticsDataCard title="Total Sales" count={totalSellAmount} percentage={sellPercent} color={sellColor}>
            <SalesCardChart sell={sellTransactions} />
          </AnalyticsDataCard>
        </Grid>
        <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

        {/* row 2 */}
        <Grid item xs={12} md={12} lg={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Income Overview</Typography>
            </Grid>
          </Grid>
          <MainCard content={false} sx={{ mt: 1.5 }}>
            <Grid item>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    sx={{ mt: 3, mr: 2 }}
                  >
                    <ToggleButtonGroup exclusive onChange={handleChange} size="small" value={slot}>
                      <ToggleButton disabled={slot === 'week'} value="week" sx={{ px: 2, py: 0.5 }}>
                        Week
                      </ToggleButton>
                      <ToggleButton disabled={slot === 'month'} value="month" sx={{ px: 2, py: 0.5 }}>
                        Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Select value={quantity} onChange={handleQuantity} size="small">
                      <MenuItem value="By Payments">By Payments</MenuItem>
                      <MenuItem value="By Revenue">By Revenue</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ pt: 1 }}>
              <IncomeChart slot={slot} quantity={quantity} />
            </Box>
          </MainCard>
        </Grid>

        {/* <Grid item xs={12} md={5} lg={4}>
          <PageViews />
        </Grid> */}

        {/* row 3 */}
        <Grid item xs={12} md={7} lg={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Recet Double Spend List</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <MainCard sx={{ mt: 2 }} content={false}>
            <OrdersList row={doubleSpend} />
          </MainCard>
        </Grid>
        {/* <Grid item xs={12} md={5} lg={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Analytics Report</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <MainCard sx={{ mt: 2 }} content={false}>
            <List sx={{ p: 0, '& .MuiListItemButton-root': { py: 2 } }}>
              <ListItemButton divider>
                <ListItemText primary="Company Finance Growth" />
                <Typography variant="h5">+45.14%</Typography>
              </ListItemButton>
              <ListItemButton divider>
                <ListItemText primary="Company Expenses Ratio" />
                <Typography variant="h5">0.58%</Typography>
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Business Risk Cases" />
                <Typography variant="h5">Low</Typography>
              </ListItemButton>
            </List>
            <ReportChart />
          </MainCard>
        </Grid> */}

        {/* row 4 */}
        {/* <Grid item xs={12} md={7} lg={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Sales Report</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="standard-select-currency"
                size="small"
                select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                sx={{ '& .MuiInputBase-input': { py: 0.75, fontSize: '0.875rem' } }}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <SalesChart />
        </Grid> */}
        {/* <Grid item xs={12} md={5} lg={4}>
          <TransactionHistory />
        </Grid> */}

        {/* row 5 */}
        {/* <Grid item xs={12} md={7} lg={8}>
          <Stack spacing={3}>
            <LabelledTasks />
            <ReaderCard />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <AcquisitionChannels />
        </Grid> */}
      </Grid>
    </>
  );
};

export default DashboardAnalytics;
