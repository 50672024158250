import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

import { useEffect, useReducer, useState } from 'react';

// import jwtDecode from 'jwt-decode';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state) => state.menu);
  const [userRole, setUserRole] = useState(1);
  const { drawerOpen } = menu;

  useEffect(() => {
    const serviceToken = window.localStorage.getItem('serviceToken');
    // const {
    //   payload: { role }
    // } = (serviceToken);
    // setUserRole(role);
  }, []);

  const navGroups = menuItem.items.map((item) => {
    let realItem = item;
    let filteredItems = item.children;
    if (userRole !== 'admin') {
      filteredItems = item.children.filter((child) => {
        return child.id !== 'customer';
      });
    }
    realItem = { ...realItem, children: filteredItems };

    switch (item.type) {
      case 'group':
        return <NavGroup key={realItem.id} item={realItem} />;
      default:
        return (
          <Typography key={realItem.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
