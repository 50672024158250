import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import logo from './bitcoin-cash-bch-logo.png';
import clipboard from "./clipboard.svg";
import './payment.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BitcoinCashQRCode = ({ address, amount }) => {

    const [coinAdd, setCoinAdd] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const size = 200; // The size of the QR code

    useEffect(() => {
        setCoinAdd(`${address}?amount=${amount}`);
    }, [`${address}?amount=${amount}`])


    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };


    return (
        <div>
            <QRCode value={coinAdd} size={size}
                imageSettings={{
                    src: logo, // source of the BTC logo
                    x: 75, // x and y coordinates to place the image at the center
                    y: 75,
                    height: 50, // height and width of the image
                    width: 50,
                    excavate: false, // to remove QR code dots where the image is placed
                }} />
            <br />
            <div className="address">
                <input className='coinAddress' value={coinAdd} ></input>
                <CopyToClipboard text={coinAdd} onCopy={onCopyText}>
                    <img src={clipboard} alt="Copy" width={20} height={30} />
                </CopyToClipboard>
            </div>
            {isCopied ? <span>Copied!</span> : null}
        </div>
    );
};

export default BitcoinCashQRCode;