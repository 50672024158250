import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import Users from 'pages/users';
import Payment from 'pages/payment'
import Details from 'pages/users/details';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - dashboard
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes =
{
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthGuard><MainLayout /></AuthGuard>,
      children: [
        {
          path: 'dashboard',
          element: <DashboardAnalytics />
        },
        {
          path: 'users',
          element: <Users />,
        },
        {
          path: 'details',
          element: <Details />,
        },
      ]
    },
    {
      path: '/payment',
      element: (
        <Payment />
      ),
    }
  ],
};

export default MainRoutes;
