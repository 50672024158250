import React, { useEffect, useMemo, useState } from 'react';
import BitcoinCashQRCode from './QRcode';
import socket from './socket';
import './payment.css';
import axios from 'axios';
import myGif from './success.gif';

export default function Payment() {
  const config = {
    auth: {
      username: 'ck_47849a3d355c98a788fc3be91262d0fe793ffa23',
      password: 'cs_926080f2784c9d65a466c37dc8b59fdae9479093'
    }
  };

  const [flag, setFlag] = useState(false);
  const [bchAmount, setbchAmount] = useState('');
  const [address, setAddress] = useState('');

  const [timeOrStatus, settimeOrStatus] = useState('');

  const calculate_time = (time) => {
    let time_temp = time / 1000;
    let mins = (time_temp - (time_temp % 60)) / 60;
    let secs = time_temp % 60;
    mins = String(mins).padStart(2, '0');
    secs = String(secs).padStart(2, '0');
    settimeOrStatus(`${mins} : ${secs}`);
  };

  const setMessage = (msg) => {
    settimeOrStatus(msg);
  };
  const params = new URLSearchParams(window.location.search);

  const orderID = params.get('orderID');

  const send_request_for_order = async (flag) => {
    const data = {
      status: 'cancelled'
    };
    if (flag) {
      data.status = 'processing';
    }
    await axios
      .post(`https://konk.market/wp-json/wc/v3/orders/${orderID}`, data, config)
      .then((response) => {
        console.log('Success change orderStatus!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [pageHidden, setPageHidden] = useState('visible');

  useEffect(() => {
    if (pageHidden === 'visible') {
      if (timeOrStatus === 'Payment has successed') {
        console.log('send request success!');
        send_request_for_order(1);
      } else if (timeOrStatus === 'Payment has expired') {
        console.log('send request success!');
        send_request_for_order(0);
      }
    }
  }, [timeOrStatus, pageHidden]);

  const handleVisibilityChange = () => {
    setPageHidden(document.visibilityState);
    if (document.visibilityState === 'visible') {
      console.log('Page is now visible');
      // location.reload();
      // const savedState = JSON.parse(localStorage.getItem('appState'));
      // let preorder = savedState.orderID;
      // socket.emit('create-wallet', { preorder })
      // socket.on('runTimeTrack', (data) => {
      //   setFlag(true);
      //   if (data.bchAmount === 'Error') {
      //     setMessage('Your orderID does not exist');
      //   }
      //   else if (data.isSend) {
      //     // send_request_for_order(1);
      //     setbchAmount(data.bchAmount);
      //     setAddress(data.address);
      //     setMessage('Payment has successed');
      //     socket.disconnect();
      //   }
      //   else if (data.remainTime <= 0) {
      //     send_request_for_order(0);
      //     setMessage('Payment has expired');
      //     socket.disconnect();
      //   }
      //   else {
      //     setbchAmount(data.bchAmount);
      //     setAddress(data.address);
      //     calculate_time(data.remainTime);
      //   }
      // });
      // console.log("socket connected again.")
    } else {
      console.log('Page is now hidden');
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // Use the parameters in your component logic
    console.log('Order ID:', orderID);

    if (orderID) {
      socket.emit('create-wallet', { orderID });

      socket.on('runTimeTrack', (data) => {
        setFlag(true);
        if (data.bchAmount === 'Error') {
          setMessage('Your orderID does not exist');
        } else if (data.isSend) {
          setbchAmount(data.bchAmount);
          setAddress(data.address);
          setMessage('Payment has successed');
          socket.disconnect();
        } else if (data.remainTime <= 0) {
          send_request_for_order(0);
          setMessage('Payment has expired');
          socket.disconnect();
        } else {
          setbchAmount(data.bchAmount);
          setAddress(data.address);
          calculate_time(data.remainTime);
        }
      });
    }
  }, [orderID]);

  const sucessDiv = (
    <div>
      <h2 className="status"> Congratulations.Your payment was successful.</h2>
      <div className="animationDiv">
        <img className="centerImage" src={myGif} alt="Description of processing"></img>
      </div>
    </div>
  );

  const showPaymentPanel =
    timeOrStatus === 'Your orderID does not exist' ? (
      <h2 className="status">
        {' '}
        Your orderID does not exist. Please contact the store owner with your transaction ID if you(partially) paid.
      </h2>
    ) : timeOrStatus === 'Payment has expired' ? (
      <h2 className="status">Your payment has expired.Please contact the store owner with your transaction ID if you(partially) paid.</h2>
    ) : timeOrStatus === 'Payment has successed' && pageHidden === 'visible' ? (
      sucessDiv
    ) : (
      <div>
        <p className="paymentDescription">
          Now it’s time to pay by scanning the QR with your Bitcoin Cash wallet or click the copy button below to copy the address, paste
          into your wallet and hit send! You will be notified straight away that the payment was successful.
        </p>
        <div className="paymentPanel row">
          <h1>BCH {Number(bchAmount).toFixed(8)}</h1>
          <br />
          <BitcoinCashQRCode address={address} amount={Number(bchAmount).toFixed(8)} />
          <h4>{timeOrStatus}</h4>
        </div>
      </div>
    );

  return (
    <div className="bg-back">
      <div className="container">{flag ? showPaymentPanel : ''}</div>
    </div>
  );
}
