import { useRoutes } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import DashboardAnalytics from 'pages/dashboard/analytics';
import { element } from 'prop-types';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

  return useRoutes([
    {
      path: '/',
      element: <AuthGuard>
        <MainLayout />
      </AuthGuard>,
      children: [
        {
          path: '/',
          element: < DashboardAnalytics />
        }
      ]

    },
    LoginRoutes,
    MainRoutes
  ]);
}
