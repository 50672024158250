// third-party
import { FormattedMessage } from 'react-intl';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
// icons
const icons = {
  DashboardIcon,
  PeopleIcon,
  ProductionQuantityLimitsIcon
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      icon: icons.DashboardIcon,
      url: '/',
    },
    {
      id: 'users',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/users',
      icon: icons.PeopleIcon,
    },
  ]
};

export default applications;
