import axios from "axios";
import config from "../config";

export const getUsers = async () => {
    try {
        const res = await axios.get(`${config.apiUrl}/admin/users`)
        if (res.status === 200) {
            return res.data
        }
    } catch (e) {
        console.log('error in getting users', e)
        return []
    }
}

export const getDashboard = async () => {
    try {
        const res = await axios.get(`${config.apiUrl}/admin/dashboard`)
        if (res.status === 200) {
            return res.data
        }
    } catch (e) {
        console.log('error in getting dashboardData', e)
        return []
    }
}

export const getWeek = async () => {
    try {
        const res = await axios.get(`${config.apiUrl}/admin/dashboard/week`)
        if (res.status === 200) {
            return res.data
        }
    } catch (e) {
        console.log('error in getting dashboardData', e)
        return []
    }
}

export const getMonth = async () => {
    try {
        const res = await axios.get(`${config.apiUrl}/admin/dashboard/month`)
        if (res.status === 200) {
            return res.data
        }
    } catch (e) {
        console.log('error in getting dashboardData', e)
        return []
    }
}

export const adminSignIn = async (data) => {
    try {
        const res = await axios.post(`${config.apiUrl}/admin/signin`, data);
        return res
    } catch (e) {
        console.log('error in login', e)
        return []
    }
}

export const resetPassword = async (data) => {
    try {
        const res = await axios.post(`${config.apiUrl}/admin/resetPassword`, data);
        return res;
    }
    catch (e) {
        console.log('error in resetPassword', e);
        return []
    }
}
