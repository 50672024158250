import { Box, useMediaQuery } from '@mui/material';
// project import
import useConfig from 'hooks/useConfig';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <div style={{ marginLeft: 'auto' }}>
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {matchesXs && <MobileSection />}
    </div>
  );
};

export default HeaderContent;
