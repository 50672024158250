import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import useConfig from 'hooks/useConfig';

// third-party
import ReactApexChart from 'react-apexcharts';
import { getWeek, getMonth } from 'api/user';
// chart options
const areaChartOptions = {
  chart: {
    height: 355,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      type: 'vertical',
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight',
    width: 1
  },
  grid: {
    show: true,
    borderColor: '#90A4AE',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  }
};

// ==============================|| INCOME LINE CHART ||============================== //

const IncomeAreaChart = ({ slot, quantity }) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [tranWeekPayments, setTranWeekPayments] = useState({})
  const [tranMonthPayments, setTranMonthPayments] = useState({})
  const [tranWeekRevernue, setTranWeekRevernue] = useState([])
  const [tranMonthRevernue, setTranMonthRevernue] = useState([])

  const [options, setOptions] = useState(areaChartOptions);

  const init = useCallback(async () => {
    const resWeek = await getWeek();
    const resMonth = await getMonth();
    // console.log(resMonth)
    try {
      setTranWeekPayments(resWeek["Payments"]);
      setTranMonthPayments(resMonth["Payments"]);
      setTranWeekRevernue(resWeek["Revenue"].revenue);
      setTranMonthRevernue(resMonth["Revenue"].revenue);
    }
    catch (e) {
      console.log("None database")
    }
  }, [])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        categories:
          slot === 'month'
            ? tranMonthPayments.month
            : tranWeekPayments?.day,
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        tickAmount: slot === 'month' ? 11 : 7
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: mode === 'dark' ? 'dark' : 'light',
        y: {
          formatter(val) {
            return `$ ${val}`;
          }
        }
      }
    }));
  }, [mode, primary, secondary, line, theme, slot, tranWeekPayments, tranMonthPayments]);

  const [series, setSeries] = useState([{
    name: 'Income',
    data: [51, 40, 28, 51, 42, 109, 100]
  }]);

  useEffect(() => {
    let tempWeek = [51, 40, 28, 51, 42, 109, 100];
    let tempMonth = [120, 85, 101, 98, 87, 105, 91, 114, 94, 86, 115, 35];
    let tempRevernueWeek = [51, 40, 28, 51, 42, 109, 100];
    let tempRevernueMonth = [120, 85, 101, 98, 87, 105, 91, 114, 94, 86, 115, 35];
    try {
      if (Object.keys(tranWeekPayments).length) tempWeek = tranWeekPayments.sum, tempRevernueWeek = tranWeekRevernue;
      if (Object.keys(tranMonthPayments).length) tempMonth = tranMonthPayments.sum, tempRevernueMonth = tranMonthRevernue;
    }
    catch (e) {
      console.log("Error");
    }
    switch (quantity) {
      case 'By Payments':
        setSeries([
          {
            name: 'Income',
            data: slot === 'month' ? tempMonth : tempWeek
          }
        ]);
        break;

      case 'By Revenue':
        setSeries([
          {
            name: 'Income',
            data: slot === 'month' ? tempRevernueMonth : tempRevernueWeek
          }
        ]);
        break;
      default:
        break;
    }
  }, [slot, quantity, tranMonthPayments, tranWeekPayments]);

  return <ReactApexChart options={options} series={series} type="area" height={355} />;
};

IncomeAreaChart.propTypes = {
  slot: PropTypes.string,
  quantity: PropTypes.any
};

export default IncomeAreaChart;
