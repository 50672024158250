import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { adminSignIn } from 'api/user';
import { openSnackbar } from 'store/reducers/snackbar';
import { useDispatch } from 'react-redux';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.payload.loggedTime + 24 * 60 * 60 > Math.floor(Date.now() / 1000);
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const snackDispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const {
            payload: { email, loggedTime }
          } = jwtDecode(serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: { email, loggedTime }
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (_data) => {
    const response = await adminSignIn(_data);
    if (response.status === 200 && response.data.success) {
      const serviceToken = response.data.data;
      window.localStorage.setItem('serviceToken', serviceToken);
      setSession(serviceToken);
      const {
        payload: { email, loggedTime }
      } = jwtDecode(serviceToken);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: { email, loggedTime }
        }
      });
      snackDispatch(
        openSnackbar({
          open: true,
          message: 'Logged successfully.',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } else {
      snackDispatch(
        openSnackbar({
          open: true,
          message: response?.data?.msg,
          variant: 'alert',
          alert: {
            color: 'warning'
          },
          close: false
        })
      );
    }
  };

  const logout = () => {
    setSession(null);
    window.localStorage.removeItem('serviceToken');
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
