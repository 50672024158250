// project import
import applications from './applications';
// import dashboard from './dashboard';
// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import other from './other';
// import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [applications] //, widget, formsTables, chartsMap, pages, other, dashboard,
};

export default menuItems;
